import {
  Button,
  Chip,
  Divider,
  Grid2,
  Typography,
  CardContent,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./SignUp.css";
import picture from "./../../assets/login.png";
import personR from "./../../assets/personR.png";
import facebook from "./../../assets/facebook.png";
import google from "./../../assets/google.png";
import arrow from "./../../assets/arrow-down-01-round.png";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { loginWithSocial, signup } from "../../redux/auth";
import "react-country-state-city/dist/react-country-state-city.css";
import { GetCountries } from "react-country-state-city";
import { uploadTheFile } from "../../redux/media";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth0 } from "@auth0/auth0-react";
import { getFollowingList } from "../../redux/tags";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // paddingLeft: '40px'
};

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: "relative",
};

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};

const signupStyle = {
  backgroundColor: "rgba(188, 23, 29, 0.1)",
  color: "rgba(188, 23, 29, 1)",
  borderRadius: "25px",
  height: "38px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  cursor: "pointer",
  textTransform: "none",
};

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("First Name is required")
    .min(3, "First Name must be at least 3 characters"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .min(3, "Last Name must be at least 3 characters"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

function SignUp() {
  const [countryid, setCountryid] = useState("");
  const [stateid, setStateid] = useState("");
  const [cityid, setCityid] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null); // Store the file
  const [imagePreview, setImagePreview] = useState(null); // Store the image URL
  const [showResetPassScreen, setShowResetPassScreen] = useState(false);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const { followingList } = useSelector((state) => state.tags);

  // INSTANCE

  let dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const googleId = process.env.REACT_APP_GOOGLE_ID;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      country: countryid,
      city: cityid,
      state: stateid,
      password: "",
      confirmPassword: "",
      userPicture: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.email = values.email.toLowerCase();
      try {
        // Check if a file is selected for upload
        if (selectedFile) {
          let formData = new FormData();
          formData.append("file", selectedFile);

          // Dispatch the file upload action
          let respFile = await dispatch(uploadTheFile(formData));

          // Check if the file upload was successful
          if (respFile.status === 200) {
            // Assign the uploaded file URL to values.userPicture
            values.userPicture = respFile.data.url;
          } else {
            return toast.error("File upload failed!");
          }
        }

        // Dispatch signup action with form values, including userPicture
        let resp = await dispatch(signup(values));

        // Handle the response from the signup action
        if (resp.status === 200) {
          toast.success(resp.data.message);
          formik.resetForm();
          setShowResetPassScreen(true);
        } else {
          toast.error(resp.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
      }
    },
  });

  useEffect(() => {
    GetCountries()
      .then((result) => {
        setCountriesList(result); // Correct variable name
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleFileUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      // Set the file to state
      setSelectedFile(file);

      // Create an object URL to display the image preview
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    } else {
      setSelectedFile(null);
      setImagePreview(null);
    }
  };


  useEffect(() => {
    // Check if both profile and provider are available
    if (profile && provider) {
      const userData = {
        firstName: profile?.givenName || profile?.name,
        lastName: profile?.familyName || profile?.name,
        email: profile?.email || profile?.email || "",
        smuSource: provider, // The provider (either 'google' or 'facebook')
        picture:
          provider === "facebook"
            ? profile?.picture?.data?.url || ""
            : profile?.profilePicture || "",
        pnSection: "Edfluencer",
        email_verified: true,
        sub:
          provider === "facebook"
            ? `facebook|${profile?.id || ""}`
            : profile?.googleUserId || "",
      };

      // Example of dispatching action with userData
      const login = async () => {
        try {
          // Dispatch your login action here with userData as the payload
          const resp = await dispatch(loginWithSocial(userData));

          if (resp?.status === 200) {
            // Save token & user info in local storage
            localStorage.setItem("token", resp.data?.token);
            localStorage.setItem(
              "userInfo",
              JSON.stringify(resp.data?.userData)
            );
            localStorage.setItem("Temptoken", resp.data?.token);
            localStorage.setItem(
              "TempuserInfo",
              JSON.stringify(resp.data?.userData)
            );
            localStorage.setItem(
              "baseFollowingList",
              JSON.stringify(followingList)
            );

            // Dispatch an action to get the following list (optional)
            dispatch(getFollowingList());

            // Show success toast
            toast.success("Login successful, Welcome!");

            // Redirect to dashboard or any page
            navigate("/app/pages");
            window.location.reload(); // If you need to reload the page after login
          }
        } catch (error) {
          // Handle any errors during the login process
          console.error("Login failed:", error);
          toast.error("Something went wrong. Please try again.");
        }
      };

      // Call the login function
      login();
    }
  }, [profile, provider, dispatch]);

  return (
    <Grid2
      container
      style={{
        display: "flex",
        alignItems: isMobile ? "center" : "start",
        justifyContent: isMobile ? "center" : "center",
        margin: isMobile ? "0px" : "40px 0px 40px 0px",
      }}
    >
      {!isMobile ? (
        <Grid2
          item
          size={7.1}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingLeft: "65px",
          }}
        >
          <img
            src={picture}
            alt="Logo"
            style={{ height: "556px", width: "462px" }}
          />
        </Grid2>
      ) : null}

      {showResetPassScreen ? (
        <Card
          style={{
            boxShadow: "10px 10px 30px 0px #6271EB33",
            borderRadius: "24px",
            height: "200px",
            marginTop: "3rem",
          }}
        >
          <CardContent>
            <Grid2
              container
              size={12}
              alignItems="center"
              justifyContent="center"
            >
              <Grid2 item size={10}>
                <Typography
                  variant="h6"
                  style={{
                    color: "#213641",
                    fontSize: "30px",
                    marginTop: "2rem",
                  }}
                >
                  Email Sent
                </Typography>
              </Grid2>
              <Grid2 item size={10}>
                <Typography variant="subtitle1" style={{ color: "#829096" }}>
                  We’ve sent you a verification link to your <br /> email
                  address. Please check your inbox.
                  {/* <span
                    style={{
                      color: "rgba(188, 23, 29, 1)",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {formik?.values.email}
                  </span>{" "}
                  <br />
                  Please check your inbox. */}
                </Typography>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      ) : (
        <Grid2
          item
          size={isMobile ? 10 : 3}
          style={{
            alignSelf: "flex-start",
            paddingTop: "65px",
            alignItems: isMobile ? "center" : "end",
            display: "flex",
            justifyContent: isMobile ? "center" : "start",
          }}
        >
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid2 item size={12} style={centerStyle}>
              <Typography
                style={{
                  color: "rgba(63, 63, 63, 1)",
                  fontFamily: "Cako regular",
                  fontWeight: 400,
                  fontSize: "24px",
                  marginBottom: "10px ",
                }}
              >
                Sign Up
              </Typography>
            </Grid2>

            <Grid2 container item size={12} spacing={2} paddingBottom={"10px"}>
              <Grid2 item size={6}>
                <label htmlFor="firstName" style={labelStyle}>
                  First Name
                </label>
                <Input
                  id="firstName"
                  style={{ ...inputStyle, width: "100%" }}
                  className="inputTextStyling"
                  placeholder="First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </Grid2>
              <Grid2 item size={6}>
                <label htmlFor="lastName" style={labelStyle}>
                  Last Name
                </label>
                <Input
                  id="lastName"
                  className="inputTextStyling"
                  style={{ ...inputStyle, width: "100%" }}
                  placeholder="Last Name"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </Grid2>
            </Grid2>

            <Grid2 item size={12} paddingBottom={"10px"}>
              <label htmlFor="email" style={labelStyle}>
                Email
              </label>
              <Input
                id="email"
                style={{ ...inputStyle, width: "100%" }}
                placeholder="abc@gmail.com"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.email}
                </div>
              ) : null}
            </Grid2>

            <Grid2 container item size={12} spacing={2} paddingBottom={"20px"}>
              <Grid2 item size={6}>
                <label htmlFor="password" style={labelStyle}>
                  Password
                </label>
                <Input.Password
                  id="password"
                  style={{ ...inputStyle, width: "100%" }}
                  placeholder="Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
                {formik.touched.password && formik.errors.password ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.password}
                  </div>
                ) : null}
              </Grid2>

              <Grid2 item size={6}>
                <label htmlFor="confirmPassword" style={labelStyle}>
                  Confirm Password
                </label>
                <Input.Password
                  id="confirmPassword"
                  style={{ ...inputStyle, width: "100%" }}
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </Grid2>
            </Grid2>
            <label
              htmlFor="password"
              style={{
                color: "rgba(112, 112, 112, 1)",
                fontFamily: "Aileron",
                fontSize: "12px",
                fontWeight: 400,
                paddingBottom: "10px",
              }}
            >
              Upload picture
            </label>
            <Grid2
              marginTop={"10px"}
              item
              size={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                marginBottom: "15px",
                position: "relative",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "80px",
                border: "1px solid rgba(112, 112, 112, 0.5)",
                borderRadius: "10px",
              }}
            >
              {imagePreview ? (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={imagePreview}
                    alt="Uploaded Pic"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "70px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      marginLeft: "10px",
                    }}
                  />
                  <Typography
                    className="picnotePictureHeader"
                    paddingLeft={"20px"}
                  >
                    Choose your image{" "}
                    <span
                      style={{
                        color: "rgba(188, 23, 29, 1)",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        document.getElementById("pictureLink").click()
                      }
                    >
                      browse
                    </span>
                  </Typography>
                  <input
                    hidden
                    type="file"
                    accept="image/jpeg, image/jpg, image/png"
                    id="pictureLink"
                    onChange={handleFileUpload}
                  />
                </span>
              ) : (
                <>
                  <label htmlFor="image-upload">
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        paddingLeft: "20px",
                      }}
                    >
                      <img src={personR} alt="Placeholder" />
                      <Typography
                        className="picnotePictureHeader"
                        paddingLeft={"20px"}
                      >
                        Choose your image{" "}
                        <span
                          style={{
                            color: "rgba(188, 23, 29, 1)",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            document.getElementById("pictureLink").click()
                          }
                        >
                          browse
                        </span>
                      </Typography>
                    </span>
                  </label>

                  <input
                    hidden
                    type="file"
                    id="pictureLink"
                    accept="image/jpeg, image/jpg, image/png"
                    onChange={handleFileUpload}
                  />
                </>
              )}
            </Grid2>

            <Grid2 item size={12} paddingBottom={"20px"}>
              <Button
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: "rgba(188, 23, 29, 1)",
                  color: "#fff",
                  borderRadius: "25px",
                  textTransform: "none",

                  mt: 2,
                  "&:hover": {
                    backgroundColor: "rgba(188, 23, 29, 0.8)",
                  },
                }}
              >
                Sign Up
              </Button>
            </Grid2>

            <Grid2 item size={12} paddingBottom={"20px"}>
              <Divider>
                <Chip label="Or" size="small" />
              </Divider>
            </Grid2>

            <Grid2 container item size={12} spacing={2}>
              <Grid2 item size={12}>
                <LoginSocialGoogle
                  isOnlyGetToken
                  client_id={googleId}
                  scope="email"
                  onResolve={({ provider, data }) => {
                    const { access_token } = data;

                    // Fetch user info from Google's userinfo endpoint
                    fetch("https://www.googleapis.com/oauth2/v2/userinfo", {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                      },
                    })
                      .then((response) => response.json())
                      .then((userInfo) => {
                        const googleUserId = `google-oauth2|${userInfo.id}`;
                        setProvider(provider);
                        setProfile({
                          userName: userInfo.name,
                          familyName: userInfo.family_name,
                          givenName: userInfo.given_name,
                          email: userInfo.email,
                          profilePicture: userInfo.picture,
                          googleUserId,
                        });
                      })
                      .catch((error) => {
                        console.error("Error fetching user info:", error);
                      });
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <Button fullWidth style={signupStyle}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        src={google}
                        alt="Google"
                        style={{ width: "20px", height: "20px" }}
                      />
                      &nbsp; Google
                    </span>
                  </Button>
                </LoginSocialGoogle>
              </Grid2>
              
            </Grid2>

            <Grid2
              item
              size={12}
              justifyContent="center"
              alignItems={"center"}
              display={"flex"}
              style={{ marginTop: "2rem", paddingBottom: "20px" }}
            >
              <span style={{ color: "#829096" }}>
                already have an account?{" "}
                <Link
                  to="../login"
                  style={{
                    color: "rgba(188, 23, 29, 1)",
                    fontWeight: 600,
                    textDecoration: "none",
                  }}
                >
                  Log in
                </Link>
              </span>
            </Grid2>
          </form>
        </Grid2>
      )}
    </Grid2>
  );
}

export default SignUp;
